<template>
    <div>
        <div v-if="load == 1">
            <el-skeleton :loading="true" :animated="true">
                <template slot="template">
                    <div class="content-box">
                        <div class="box">
                            <div class="top">
                                <div class="content" style="width: 100%;">
                                    <div class="user">
                                        <el-skeleton-item variant="image" style="width:45px; height:45px" />
                                        <div class="name-box">
                                            <p class="name ellipsis-1">
                                                <el-skeleton-item variant="text" style="width:120px" />
                                            </p>
                                            <p class="time">
                                                <el-skeleton-item variant="text" style="width: 100px" />
                                            </p>
                                        </div>
                                    </div>
                                    <h3 class="title">
                                        <el-skeleton-item variant="h3" style="width: 60%" />
                                    </h3>

                                    <div class="dy-content">
                                        <div class="p">
                                            <el-skeleton-item variant="text" style="width: 40%" />
                                        </div>
                                        <div class="p">
                                            <el-skeleton-item variant="text" style="width: 80%" />
                                        </div>
                                        <div class="p">
                                            <el-skeleton-item variant="text" style="width: 50%" />
                                        </div>
                                        <div class="p">
                                            <el-skeleton-item variant="text" style="width: 80%" />
                                        </div>
                                        <div class="p">
                                            <el-skeleton-item variant="text" style="width: 70%" />
                                        </div>
                                        <div class="p">
                                            <el-skeleton-item variant="text" style="width: 80%" />
                                        </div>
                                    </div>
                                    <div class="img-box">
                                        <el-skeleton-item variant="image" style="width: 250px; height: 250px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-skeleton>
        </div>
        <div class="nomsg" v-else>{{
      load
    }}</div>
    </div>
</template>

<script>
    export default {
        name: "desLoad",
        props: ["load"],
        data() {
            return {};
        },
        created() {},
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {},
        computed: {
            // 计算属性
        },
        watch: {},
    };
</script>

<style lang="less" scoped>
    @import "@assets/less/des.less";

    .box {
        box-shadow: 0 0 0 transparent;
    }

    .p {
        margin-top: 10px;
    }

    .nomsg {
        text-align: center;
        color: #aaa;
        margin-top: 50px;
    }
</style>