<template>
    <div>
        <div class="comment-wrap w-bg" v-if="list && list.length > 0">
            <ul class="c-ul">
                <li v-for="(m, index) in list">
                    <div class="li">
                        <img class="tx" :src="rImg(m.head_portrait)" :onerror="txImg" alt="">
                        <div class="right" @click.stop="setHuifu(m)">
                            <div class="flex-bt name-wrap">
                                <p class="name ellipsis-1">{{m.name}}</p>
                                <span class="zan" @click.stop="zanPing(m)" :class="{'active':m.is_digg}">{{m.digg_count}}<i class="hwiconfont icon-dianzan3" :class="{'icon-dianzan':!m.is_digg}"></i></span>
                            </div>
                            <div class="c-content">{{m.content}} </div>
                            <p class="time">{{m.addtime}}
                            </p>
                        </div>
                    </div>
                    <div class="commet-child" v-if="m.nextcount">
                        <div class="li" v-for="(c, ci) in m.childs">
                            <img class="tx" :src="rImg(c.head_portrait)" :onerror="txImg" alt="">
                            <div class="right" @click.stop="setHuifu(c, m.comment_id)">
                                <div class="flex-bt name-wrap">
                                    <div class="name-box">
                                        <div class="name ellipsis-1">{{c.name}}</div>
                                        <div v-if="c.touser" class="name next-name ellipsis-1">回复 <span @click.stop="go_userInfo(c.touser.id, c.touser.name)">{{c.touser.name}}</span></div>
                                    </div>
                                    <span class="zan" @click.stop="zanPing(c)" :class="{'active':c.is_digg}">{{c.digg_count}}<i class="hwiconfont icon-dianzan3" :class="{'icon-dianzan':!c.is_digg}"></i></span>
                                </div>
                                <div class="c-content">{{c.content}} </div>
                                <p class="time">{{c.addtime}}
                                    <!-- <span class="hf-btn">回复</span> -->
                                </p>
                            </div>
                        </div>
                        <div @click.stop="getMoreCommet(m)" class="show-more" v-if="m.nextcount - m.childs.length > 0"><span class="line"></span>展开{{m.nextcount - m.childs.length}}条回复</div>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else>
            <p class="ping-tip">暂无评论，抢占沙发</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: "pDyList",
        components: {},
        props: ["list", "dyid"],
        data() {
            return {
                loading: false,
            };
        },
        created() {},
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            setHuifu(m, pid) {
                this.$emit('setHuifu', {
                    m: m,
                    pid: pid
                });
            },
            // 点赞评论
            async zanPing(c) {
                let {
                    code,
                    msg
                } = await this.$y_ajax('api/v2.dymanic/commentDigg', {
                    type: c.is_digg ? 2 : 1, //  1 点赞 2 取消点赞
                    comment_id: c.comment_id,
                })
                this.$y_msg(msg);
                if (code == 200) {
                    if (c.is_digg) {
                        // 已点赞 欲取消
                        c.is_digg = false;
                        c.digg_count--;
                    } else {
                        c.is_digg = true;
                        c.digg_count++;
                    }
                }

            },
            // 加载更多回复
            async getMoreCommet(m) {
                let page = Math.floor(m.childs.length / 10) + 1;
                let {
                    code,
                    data
                } = await this.$y_ajax('api/v2.dymanic/comments', {
                    dy_id: this.dyid,
                    pid: m.comment_id,
                    page: page,
                    pagesize: 10,
                })
                if (code == 200) {
                    if (page == 1 && data.length) {
                        data.splice(0, 3); // 评论列表默认返回3条下级评论
                    }
                    m.childs = m.childs.concat(data);
                }
            },
        },
        computed: {},
        watch: {
            // 监听
        },
    };
</script>

<style lang="less" scoped>
    .comment-wrap {
        .c-ul {

            .li {
                display: flex;
                padding: 10px;

                .tx {
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }

                .right {
                    flex: 1;
                }

                .name {
                    margin-bottom: 0;
                }

                .time {
                    color: #B3B3B3;
                    font-size: 12px;

                }

                .c-content {
                    word-break: break-all !important;
                    color: #333;
                    font-size: 16px;
                    margin: 5px 0;
                }

                .zan {
                    .hwiconfont {
                        margin-left: 5px;
                    }

                    &.active {
                        color: #fd5621;

                        .hwiconfont {
                            color: #fd5621;
                        }
                    }
                }

            }

            .name-wrap {
                width: 100%;

                .name {
                    font-size: 14px;
                    color: #757575;
                }

                .time {
                    font-size: 12px;
                }

                .name-box {
                    display: inline-flex;
                    flex: 1;
                    align-items: center;

                    .next-name {
                        margin-left: 7px;

                        span {
                            font-size: 12px;
                            color: #aaa;
                        }

                    }
                }
            }
        }
    }


    .commet-child {
        margin-left: 30px;

        .li {
            background: #fafafa;

        }

        .show-more {
            display: inline-flex;
            align-items: center;
            color: #fd5621;
            font-size: 12px;
            margin-top: 10px;

            .line {
                width: 20px;
                height: 1px;
                background: #eee;
                margin-right: 10px;
            }
        }



        .name-wrap {
            .name {
                max-width: 150px;
            }

            next-name {
                margin-left: 7px;

                span {
                    font-size: 12px;
                    color: #aaa;
                }
            }
        }
    }


    .ping-tip {
        text-align: center;
        padding: 30px 0;
        font-size: 0.6rem;
        color: #aaa;
    }
</style>