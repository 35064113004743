<template>
    <div class="view">
        <header>
            <div class="head flex-bt">
                <div class="left flex">
                    <i class="hwiconfont icon-fanhui" @click="close()"></i>
                    <div class="user-box" v-if="m.userid">
                        <img :src="rImg(m.head_100)" :onerror="txImg" class="tx" alt="">
                        <span class="name ellipsis-1">{{m.name}}</span>
                    </div>
                </div>
                <div class="right">
                    <button class="gz-btn" v-if="myid!=m.userid && m.source_type!=6" :class="{'ready':m.is_attention}" @click.stop="gzDy()">{{m.is_attention?'已关注':'关注'}}</button>
                    <i class="hwiconfont icon-fenxiang" @click.stop="showM=1"></i>
                </div>
            </div>
        </header>

        <div class="wrap" style="padding-bottom: 100px;" v-infinite-scroll="getPingList">
            <desLoad v-if="nomsg" :load="nomsg"></desLoad>
            <div class="content-box" v-else>
                <div class="box">
                    <div class="top">
                        <div class="content">
                            <div class="user">
                                <img :src="rImg(m.head_100)" :onerror="txImg" class="tx" alt="" />
                                <div class="name-box">
                                    <p class="name ellipsis-1">{{ m.name }}</p>
                                    <p class="time">{{ m.addtime }}</p>
                                </div>
                            </div>
                            <div class="user-wrap" v-if="m.hebian_users && m.hebian_users.length">
                                <h3>本作品由以下用户附编</h3>
                                <div class="user-ul">
                                    <img :src="rImg(p.head_portrait)" :onerror="txImg" class="tx" v-for="(p, pi) in m.hebian_users" alt="">
                                </div>
                            </div>
                            <div v-if="m.paystate || m.price == 0">
                                <div class="img-box" v-if="m.video" @click="openVideo(m.video)">
                                    <el-image class="img video-img" :src="rImg(m.cover)" fit="cover">
                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </el-image>
                                </div>
                                <div class="img-box" v-else-if="m.masterimg && m.masterimg.length && m.masterimg[0]">
                                    <img class="img" :src="rImg(item)" v-for="item in m.masterimg" alt="">
                                </div>
                            </div>
                            <h3 class="title" v-if="m.title">{{ m.title }}</h3>
                            <div class="dy-content">
                                <div class="dy-content-des" :class="{ limit: !m.paystate && m.price != 0 }" @click="openTxt()">
                                    <div class="dy-des" v-html="dealTxt()"></div>
                                    <div v-if="m.content_hebian && m.content_hebian.length">
                                        <div v-for="(m, mi) in m.content_hebian">
                                            <div :id="'id'+m.author.id" class="mix-user-box" v-if="m.author">
                                                <div class="line"></div>
                                                <div class="mix-user">
                                                    <img class="tx" :src="rImg(m.author.head_portrait)" :onerror="txImg" alt="">
                                                    <span class="name ellipsis-1">{{m.author.name}}</span>
                                                    <span class="font-s" style="margin: 0;">编辑下面内容</span>
                                                </div>
                                            </div>
                                            <div class="dy-des" v-html="m.content"></div>
                                        </div>
                                    </div>
                                    <p v-if="!m.paystate && m.price != 0" class="art-price">支付{{m.price}}金币查看全文</p>
                                </div>
                            </div>
                            <div class="tag-box">
                                <span class="tag" :key="ti" v-for="(t, ti) in m.topic">#{{ t }}</span>
                            </div>
                            <div class="tag-box">
                                <span class="tag" :key="ti" v-for="(t, ti) in m.topic_experience">&{{ t }}</span>
                            </div>
                            <div class="dy-link" v-if="m.link">
                                <i class="el-icon-link"></i>
                                <span>点击打开链接</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <pDyList :list="pingList" @setHuifu="setHuifu" :dyid="id"></pDyList>
                    <p class="more-btn" v-if="pingList.length">{{total==-1 ? '暂无更多':'加载中'}}</p>
                </div>
            </div>
        </div>
        <div class="input-bg">
            <div class="input-wrap" v-if="isInput">
                <input type="text" :placeholder="placeTxt" ref="input" v-model="inputVal">
                <button class="send-btn" @click.stop="setPing()">发送</button>
            </div>
            <div class="input-wrap input-menu" v-else>
                <span class="icon-box input-box" @click="setFocus()">
                    <i class="hwiconfont icon-bianji2"></i>
                    <span>说点什么...</span>
                </span>
                <span class="icon-box" @click.stop="scFlag()" :class="{'active':m.islover == 1}">
                    <i class="new-sc"></i>
                    <span class="num">{{m.lover}}</span>
                </span>
                <span class="icon-box" @click.stop="zanFlag()" :class="{'active':m.isthumbsup == 1}">
                    <i class="hwiconfont icon-dianzan3" :class="{'icon-dianzan':m.isthumbsup == 0}"></i>
                    <span class="num">{{m.num}}</span>
                </span>
                <span class="icon-box" @click="setFocus()">
                    <i class="hwiconfont icon-pinglun"></i>
                    <span class="num">{{m.comments || '评论'}}</span>
                </span>
            </div>
        </div>
        <div class="share-bg" v-if="showM" @click="showM = 0">
            <div class="share-box" v-if="showM == 1" @click.stop>
                <div class="share-title">
                    <h3>更多操作</h3><i @click="showM = 0" class="hwiconfont icon-guanbi"></i>
                </div>
                <ul class="share-ul">
                    <li class="li" @click.stop="copyTxt()">
                        <div class="share-icon">
                            <i class="hwiconfont icon-lianjie1"></i>
                        </div>
                        <p class="name">复制链接</p>
                    </li>
                    <li class="li" @click.stop="getHateInfo()">
                        <div class="share-icon">
                            <i class="hwiconfont icon-hate"></i>
                        </div>
                        <p class="name">不喜欢</p>
                    </li>
                    <li class="li" @click.stop="to_url('h5report', {uid:m.userid})">
                        <div class="share-icon">
                            <i class="hwiconfont icon-warn"></i>
                        </div>
                        <p class="name">举报</p>
                    </li>
                    <li class="li" @click.stop="setNominate()">
                        <div class="share-icon" :class="{'active':m.is_recommend==1}">
                            <i class="hwiconfont icon-dianzan3 " :class="{'icon-dianzan':m.is_recommend==0}"></i>
                        </div>
                        <p class="name">{{m.is_recommend ? '取消推一推':'推一推'}}</p>
                    </li>
                </ul>
            </div>
            <div class="share-box hate-box" @click.stop v-else>
                <div class="share-title">
                    <h3>选择不喜欢该作品的理由</h3><i @click="showM = 0" class="hwiconfont icon-guanbi"></i>
                </div>
                <ul class="r-ul">
                    <li class="list" @click.stop="r.select = r.select?0:1" :class="{'active':r.select==1}" v-for="(r,ri) in hateInfo.obj">
                        <div class="left"><span>{{r.title}}：</span>{{r.c.name || r.c}}</div><i class="hwiconfont icon-select_full"></i>
                    </li>
                </ul>
                <p>描述更多</p>
                <textarea class="r-content" v-model="hateTxt" name="" id="" placeholder="请描述作品中存在的问题"></textarea>
                <div class="flex-c" style="padding-bottom: 10px;">
                    <el-button style="width: 50vw;" type="primary" round @click="setHateMore()">提交</el-button>
                </div>
            </div>
        </div>
        <button style="visibility: hidden;" ref="copyBtn" id="copyBtn" :data-clipboard-text="linkUrl" data-clipboard-action="copy"></button>
    </div>
</template>
<script>
    import pDyList from "@components/common/comment-dy";
    import desLoad from "@components/common/des-load";
    import Clipboard from 'clipboard';
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            pDyList,
            desLoad,
        },
        data() {
            return {
                nomsg: 1,
                id: "",
                m: {},
                myid: 0,
                shenhe: {
                    "-1": "未通过",
                    0: "审核中",
                },
                pingList: [], // 评论列表
                total: 0,
                inputVal: '',
                isInput: 0,
                placeTxt: '说点什么',
                huifuInfo: {
                    pid: 0,
                    uid: 0
                },
                showM: 0,
                clipboard: '',
                linkUrl: '',
                hateInfo: {},
                hateTxt: ''
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.id = this.$route.query.id;
            if (!this.id) {
                this._home();
                return;
            }
            this.linkUrl = 'http://' + window.location.hostname + '/#/dydes?id=' + this.id;
            this.getData();
        },
        mounted() {
            // 在组件被挂载之后调用。
            let _this = this;
            this.clipboard = new Clipboard('#copyBtn');
            this.clipboard.on('success', function(e) {
                _this.$y_msg('复制成功');
            });
            this.clipboard.on('error', function(e) {
                _this.$y_msg('复制失败')
            });
        },
        beforeDestroy() {
            this.clipboard.destroy();
        },
        methods: {
            copyTxt() {
                this.$refs.copyBtn.click();
            },
            // 回复
            setHuifu(data) {
                let m = data.m;
                let pid = data.pid;
                var _this = this;
                if (pid) {
                    // 二级评论
                    _this.huifuInfo = {
                        pid: pid,
                        uid: m.userid
                    }
                } else {
                    _this.huifuInfo = {
                        pid: m.comment_id,
                        uid: 0
                    }
                }
                _this.setFocus('回复：' + m.name);
            },
            setFocus(t) {
                var _this = this;
                _this.isInput = 1;
                _this.placeTxt = t || '说点什么';
                if (!t) {
                    _this.huifuInfo = {
                        pid: 0,
                        uid: 0
                    }
                }
                setTimeout(function() {
                    _this.$refs.input.focus()
                }, 300);
            },
            // 内容处理
            dealTxt() {
                let m = this.m;
                let c = m.content;
                if (c) {
                    c = c.replace(/http(s)*:\/\//gi, "//");
                }
                return c;
            },
            openTxt() {
                let m = this.m;
                if (!m.paystate && m.price != 0) {
                    this.showApp();
                }
            },
            // 推荐
            async setNominate() {
                let ret = await this.$y_ajax('api/v2.dymanic/recommend', {
                    dy_id: this.id
                })
                // state 0取消 1推荐
                this.$y_msg(ret.msg);
                this.m.is_recommend = ret.state;
            },
            // 提交不喜欢
            async setHateMore() {
                let hate = this.hateInfo;
                let arr = JSON.parse(JSON.stringify(hate.obj));
                let brr = [];
                arr.forEach(function(v) {
                    if (v.select == 1) {
                        delete v.select;
                        brr.push(v)
                    }
                })
                if (!brr.length) {
                    _msg('请选择不感兴趣的理由');
                    return;
                }
                let ret = await this.$y_ajax('api/Dymanic/uninterested_desc', {
                    id: hate.id,
                    donotlike: brr,
                    content: this.hateTxt
                })
                if (ret.msg) {
                    this.$y_msg(ret.msg);
                }
                if (ret.code == 200) {
                    this.showM = 0;
                }
            },
            // 不喜欢的原因
            async getHateInfo() {
                if (Object.keys(this.hateInfo).length) {
                    this.showM = 2;
                } else {
                    let ret = await this.$y_ajax('api/Dymanic/uninterested', {
                        dy_id: this.id
                    })
                    if (ret.code == 200 && ret.data) {
                        ret.data.obj.forEach(v => {
                            v.select = 0;
                        })
                        this.hateInfo = ret.data;
                        this.showM = 2;
                    } else if (ret.msg) {
                        this.$y_msg(ret.msg)
                    }
                }
            },
            // 关注
            async gzDy() {
                let uid = this.m.id || this.m.userid;
                if (this.m.source_type == 6) {
                    this.$y_msg('该用户不可关注');
                    return;
                }
                let ret = await this.$y_ajax('api/user/attention', {
                    user_id: this.myid,
                    to_user: uid,
                })
                if (ret && ret.code == 200) {
                    this.m.is_attention = ret.msg == '关注成功' ? 1 : 0;
                }
            },
            // 获取详情
            async getData() {
                this.myid = this.$y_getKey("userid") || 0;
                let {
                    code,
                    msg,
                    result
                } = await this.$y_ajax("Home/Dymanic/dycommentlsts", {
                    dy_id: this.id,
                    userid: this.myid,
                });
                if (code == 200) {
                    this.m = result;
                    result.id = result.userid;
                    result.paystate = Number(result.paystate);
                    this.nomsg = 0;
                    this.setMeta(this.m.title);
                    if (result.comments) {
                        // 有评论数才请求评论列表
                        this.getPingList(1);
                    }
                } else {
                    this.nomsg = msg;
                }
            },
            // 点赞
            async zanFlag() {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                if (this.m.shenhe != undefined && this.m.shenhe != 1) {
                    this.$y_msg(this.shenhe[this.m.shenhe]);
                    return;
                }
                let s = parseInt(this.m.isthumbsup);
                let ret = await this.$y_ajax("api/Dymanic/thumbsup", {
                    dy_id: this.id,
                    type: s + 1, // 1点赞 2取消
                    userid: this.myid
                });
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    let t = this.m.num;
                    this.m.isthumbsup = s == 0 ? 1 : 0;
                    this.m.num = s == 0 ? parseInt(t) + 1 : t - 1;
                }
            },
            // 收藏
            async scFlag() {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                if (this.m.shenhe != undefined && this.m.shenhe != 1) {
                    this.$y_msg(this.shenhe[this.m.shenhe]);
                    return;
                }
                let s = parseInt(this.m.islover);
                let ret = await this.$y_ajax("api/Dymanic/lover", {
                    dy_id: this.id,
                    type: s + 1, // 1点赞 2取消
                });
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    if (s == 0) {
                        this.m.islover = 1;
                        this.m.lover++;
                    } else {
                        this.m.islover = 0;
                        this.m.lover--;
                    }
                }
            },
            // 评论
            async setPing() {
                let _this = this;
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                if (this.m.shenhe != undefined && this.m.shenhe != 1) {
                    this.$y_msg(this.shenhe[this.m.shenhe]);
                    return;
                }
                if (!this.inputVal) {
                    this.$y_msg("请输入评论");
                    return;
                }
                this.isInput = 0;
                this.$y_loading();
                let param = {
                    dy_id: this.id,
                    userid: this.myid,
                    type: 0, //0为评论，1为回复
                    content: this.inputVal,
                    touserid: 0
                }
                if (_this.huifuInfo.pid) {
                    param.pid = _this.huifuInfo.pid;
                    param.type = 1;
                    if (_this.huifuInfo.uid) {
                        param.touserid = _this.huifuInfo.uid;
                    }
                } else {
                    param.touserid = _this.m.userid;
                }
                let {
                    code,
                    msg
                } = await this.$y_ajax("home/Dymanic/comment", param);
                this.$y_loadingClose();
                this.$y_msg(msg);

                if (code == 200) {
                    this.inputVal = "";
                }
            },
            async getPingList(first) {
                let param = {
                    dy_id: this.id,
                    pagesize: 10
                }
                if (first) {
                    param.page = 1;
                }
                let {
                    list,
                    total
                } = await this.$y_list("api/v2.dymanic/comments", this.pingList, param);
                this.pingList = list;
                this.total = total;
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            s_getUser() {
                this.getData();
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "@assets/less/des.less";

   

    /* 头部 */
    header {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 100%;
        background: #fff;
        z-index: 1;

        .user-box {
            display: flex;
            align-items: center;
            max-width: 60vw;
        }

        .tx {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
        }

        .right {
            display: inline-flex;
            align-items: center;

            .hwiconfont {
                padding: 10px;
                font-size: 24px;
            }
        }
    }

    .gz-btn {
        color: #fff;
        font-size: 10px;
        background: #fd5621;
        border-radius: 3px;
        margin-left: 10px;
        padding: 5px 10px;

        &.ready {
            background: #aaa;
        }
    }

    .dy-content {
        position: relative;
    }

    .box {
        box-shadow: 0 0 0 transparent;

        .top {
            .img-box {
                display: inline-flex;
                flex-wrap: wrap;
                margin-top: 15px;
                padding-left: 10px;

                .img {
                    width: 150px;
                    height: 150px;
                    border-radius: 15px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    object-fit: cover;
                }
            }
        }
    }

    .user-wrap {
        padding: 10px;

        .user-ul {
            display: inline-flex;
            flex-wrap: wrap;
            padding-left: 10px;
            padding-top: 10px;

            .tx {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid #fd5621;
                margin-bottom: 10px;
            }

        }
    }



    .mix-user-box {
        margin-top: 20px;
        position: relative;

        .mix-user {
            display: inline-flex;
            align-items: center;
            background: #fff;
            margin-left: 20px;
            position: relative;
            padding: 0 10px;
        }

        .line {
            width: 100%;
            height: 0;
            border-bottom: 1px dashed #aaa;
            position: absolute;
            left: 0;
            top: 50%;
        }

        .tx {
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin: 0;
            margin-right: 7px;
        }

        .name {
            max-width: 200px;
            font-size: 12px;
        }
    }



    .dy-link {
        background: #f3f3f3;
        border-radius: 3px;
        padding: 5px 7px;
        margin-top: 7px;
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;

        .icon {
            width: 16px;
            height: 16px;
            border-radius: 3px;
        }

        .hwiconfont {
            color: #fd5621;
        }

        span {
            margin-left: 5px;
            font-size: 12px;
            color: #555;
        }
    }

    .btn-box {
        position: fixed;
        width: 100%;
        bottom: 30px;
        display: flex;
        justify-content: center;

        .app-btn {
            background: #fd5621;
            color: #fff;
        }
    }

    .input-bg {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 1;
        display: flex;
        align-items: flex-end;
    }

    .input-wrap {
        background: #fafafa;
        width: 100%;
        padding: 10px;
        display: flex;
        box-sizing: border-box;

        input {
            font-size: 14px;
            flex: 1;
            width: 100px;
            background: #fafafa;
        }

        .send-btn {
            background: #fd5621;
            color: #fff;
            border-radius: 7px;
            text-align: center;
            margin-left: 10px;
            padding: 0 15px;
            line-height: 30px;
        }
    }


    .input-menu {
        background: #fff;
        justify-content: space-between;
        align-items: center;

        .icon-box {
            display: inline-flex;
            align-items: center;


            span,
            .hwiconfont {
                color: #A7A7A7;
            }

            &.active {

                span,
                .hwiconfont {
                    color: #fd5621;

                }

            }

            .num {
                margin-left: 3px;
                font-size: 12px;
            }

            &.input-box {
                background: #f3f3f3;
                border-radius: 20px;
                padding: 10px;
                margin-top: 0;

                span {
                    color: #777;
                    margin-left: 5px;
                    font-size: 12px;
                }
            }
        }
    }

    .share-bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 2;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: flex-end;


        .share-box {
            background: #f7f7f7;
            width: 100%;
            box-sizing: border-box;
        }

        .share-title {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
                padding: 10px 0;
            }

            .hwiconfont {
                position: absolute;
                right: 0;
                padding: 10px;
            }
        }

        .share-ul {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .li {
                width: 25%;
                text-align: center;
                padding: 15px 0;
            }



            .name {
                color: #999;
                font-size: 12px;
                margin-top: 5px;
            }

        }

        .share-icon {
            width: 40px;
            height: 40px;
            background: #fff;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            .hwiconfont {
                font-size: 20px;
                color: #999;
                margin: 0;

            }

            &.active {
                .icon-dianzan3 {
                    color: #fd5621;
                }
            }
        }
    }

    .hwiconfont {
        &.icon-dianzan3 {
            color: #fd5621;
        }

        &.hwiconfont.icon-dianzan {
            color: #A7A7A7;
        }
    }


    /* 举报 */
    .hate-box {
        padding: 10px;

        .r-ul {
            background: #fafafa;
            padding: 10px;
            margin: 15px 0;
            border-radius: 7px;
            max-height: 30vh;
            overflow-y: auto;
        }

        .list {
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .hwiconfont {
            color: #ccc;
            font-size: 20px;
        }

        .active {
            .hwiconfont {
                color: #fd5621;
            }
        }

        .r-content {
            width: 100%;
            height: 100px;
            resize: none;
            padding: 10px;
            border-radius: 7px;
            background: #fafafa;
            box-sizing: border-box;
            margin: 15px 0;
        }
    }

    .tag-box {
        flex-wrap: wrap;
    }

    @media (max-width: 600px) {
        .box {
            padding: 0;

            .top {
                .img-box {
                    padding-left: 0;

                    .img {
                        width: calc((100vw - 40px) / 3);
                        height: calc((100vw - 40px) / 3);

                        &:nth-child(3n) {
                            margin-right: 0;
                        }

                    }
                }
            }
        }
    }
</style>
<style>
    .limit {
        max-height: 200px;
        overflow: hidden;
        min-height: 100px;
    }

    .art-price {
        cursor: pointer;
        text-align: center;
        height: 60%;
        padding-top: 20px;
        color: orange;
        position: absolute;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        background: linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0.8));
    }

    .dy-content-des {
        line-height: 2;
    }

    .dy-content-des pre {
        white-space: normal;
    }

    .dy-content-des img {
        max-width: 100% !important;
        display: block;
        margin-bottom: 10px;
    }

    .dy-content-des .hide-img {
        display: none;
    }

    .dy-content-des span {
        display: inline;
    }
</style>